<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Using Slater's rules, determine the effective nuclear charge,
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        , for the following electrons in
        <chemical-latex :content="atomNameDisplay" />
        atom.
      </p>

      <p class="mb-2">A <span class="text-bold">4p</span> electron:</p>

      <calculation-input
        v-model="inputs.input1"
        :prepend-text="'$\\text{Z}_{\\mathrm{eff}}:$'"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">A <span class="text-bold">3d</span> electron:</p>

      <calculation-input
        v-model="inputs.input2"
        :prepend-text="'$\\text{Z}_{\\mathrm{eff}}:$'"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">A <span class="text-bold">2s</span> electron:</p>

      <calculation-input
        v-model="inputs.input3"
        :prepend-text="'$\\text{Z}_{\\mathrm{eff}}:$'"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question31',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    atomName() {
      return this.chemicalElement.name;
    },
    atomNameDisplay() {
      if (this.atomName === 'Arsenic') {
        return 'an \\textbf{' + this.atomName.toLowerCase() + '}';
      } else {
        return 'a \\textbf{' + this.atomName.toLowerCase() + '}';
      }
    },
  },
};
</script>
